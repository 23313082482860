<template>
  <header class="header flex-c-fe">
    <RouterLink to="/applications/list">Заявки</RouterLink>
    <RouterLink to="/routes/list">Маршруты</RouterLink>
    <!-- <RouterLink to="/segments">Сегменты</RouterLink> -->
    <!-- <button class="header-notify" type="button">
      <img src="@/assets/img/bell-noti.svg" />
    </button> -->
    <div class="header-person flex-c-c">
      <img src="@/assets/img/person.svg" />
      {{ store.state.auth.me?.name }}
    </div>
    <button class="header-exit btn btn-clear" type="button" @click="logout">
      Выйти
    </button>
  </header>
</template>

<script setup>
import router from '@/router'
import { useStore } from 'vuex'

const store = useStore()

const logout = () => {
  store.commit('auth/clearTokens')
  router.push({ name: 'Auth' })
}
</script>

<style lang="scss" scoped>
.header {
  height: 95px;
  border-bottom: 1px solid #d2d2d2;
  margin: 0px 15px;
  font-size: 10px;
  gap: 16px;
  @include mobile {
    height: 63px;
  }
  &-notify {
    @include reset-button;
    width: 24px;
    height: 24px;
  }
  &-person {
    gap: 6px;
    font-weight: 700;
    img {
      max-width: 24px;
      max-height: 24px;
    }
  }
  &-exit {
    font-size: 1em;
  }
}
</style>
