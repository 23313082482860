import '@/style/index.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixins'
import './registerServiceWorker'

createApp(App).use(store).use(router).mixin(mixin).mount('#app')

// push
