const DB_NAME = 'tele2_photobinding_db'
const DB_TABLE_ORDER = 'applications'
const DB_TABLE_DATASET = 'dataset'

/**
 * @type {IDBOpenDBRequest}
 */
let openRequest
/**
 * @type {IDBDatabase}
 */
let db
let deleteRequest

export async function getDB() {
  return new Promise((response, reject) => {
    openDB()

    openRequest.onupgradeneeded = function (e) {
      db = openRequest.result
      switch (e.oldVersion) {
        case 0:
          db.createObjectStore(DB_TABLE_ORDER, {
            keyPath: 'id',
            autoIncrement: true,
          })
        case 1:
          db.createObjectStore(DB_TABLE_DATASET, {
            keyPath: 'id',
            autoIncrement: true,
          })
          break;
        default:
          break;
      }
    }
    openRequest.onerror = (error) => {
      console.error('Error: ', error)
      reject(error)
    }
    openRequest.onsuccess = async (event) => {
      db = event.target.result


      // если версия поменялась, а пользователь пытается пользоваться старой версией БД
      db.onversionchange = function () {
        db.close()
        alert('База данных устарела, пожалуйста, перезагрузите страницу.')
      }
      response(db)
    }
  })
}

export function getDatasetStore() {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(DB_TABLE_DATASET, 'readonly')
    const store = transaction.objectStore(DB_TABLE_DATASET)
    const dataset = store.getAll()
    transaction.oncomplete = () => resolve(dataset)
    transaction.onerror = (error) => reject(error)
  })
}

/**
 * @param {Dataset} data 
 */
export async function setDatasetStore(data) {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_DATASET, 'readwrite')
    const store = transaction.objectStore(DB_TABLE_DATASET)
    store.add(data)
    transaction.oncomplete = () => response('Ok')
    transaction.onerror = (error) => reject(error)
  })
}

/**
 * @param {Dataset} data 
 */
export async function deleteDatasetStore() {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_DATASET, 'readwrite')
    const store = transaction.objectStore(DB_TABLE_DATASET)
    store.delete('dataset')
    transaction.oncomplete = () => response('Ok')
    transaction.onerror = (error) => reject(error)
  })
}

export async function getApplicationsStore() {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_ORDER, 'readonly')
    const store = transaction.objectStore(DB_TABLE_ORDER)
    let applications = []
    store.openCursor().onsuccess = (event) => {
      const cursor = event.target.result
      if (cursor) {
        applications.push(cursor.value)
        cursor.continue()
      }
      transaction.oncomplete = () => response(applications)
      transaction.onerror = (error) => reject(error)
    }
  })
}
export async function getApplicationId(id) {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_ORDER, 'readonly')
    const store = transaction.objectStore(DB_TABLE_ORDER)
    const application = store.get(id)
    transaction.oncomplete = () => response(application.result)
    transaction.onerror = (error) => reject(error)
  })
}
export async function postApplication(application) {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_ORDER, 'readwrite')
    const store = transaction.objectStore(DB_TABLE_ORDER)
    store.add(application)
    transaction.oncomplete = () => response('Ok')
    transaction.onerror = (error) => reject(error)
  })
}
export async function putApplication(application) {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_ORDER, 'readwrite')
    const store = transaction.objectStore(DB_TABLE_ORDER)
    store.put(application)
    transaction.oncomplete = () => response('Ok')
    transaction.onerror = (error) => reject(error)
  })
}
export async function deleteApplication(id) {
  return new Promise((response, reject) => {
    const transaction = db.transaction(DB_TABLE_ORDER, 'readwrite')
    const store = transaction.objectStore(DB_TABLE_ORDER)
    store.delete(id)
    transaction.oncomplete = () => response('Deleted')
    transaction.onerror = (error) => reject(error)
  })
}

// создать БД
function openDB() {
  openRequest = indexedDB.open(DB_NAME, +process.env.VUE_APP_DB_VERSION || 1)
}
// удалить БД
export function deleteDB() {
  deleteRequest = indexedDB.deleteDatabase(DB_NAME)
  deleteRequest.onsuccess = function () {
    console.log(`БД: ${DB_NAME} удалена`)
  }
  deleteRequest.onerror = function () {
    console.error(`БД: ${DB_NAME} ошибка в удалении`)
  }
}
