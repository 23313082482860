import request from '@/utils/request'

/**
 * @param {FormData} data 
 * @returns {access_token: string, refresh_token: string, expires_in: number}
 */
export function postAuthLogin(data) {
  return request({
    url: `/api/oauth/token`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
