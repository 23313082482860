import md5 from 'md5'
import '@/utils/types.js'

const state = () => ({
  status: 'succeed',
  banners: [],
  selected: [],
  defaultTransform: {
    idHash: null,
    perspective: 1000,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    matrix3d: null,
    scale: 1,
    skewX: 0,
    skewY: 0,
    active: false,
    targetPosition: null,
    wishes_visual: '',
    frame: '',
    mounting: '',
    width: null,
    height: null,
    material: null,
  },
})

const mutations = {
  setStateProps(state, payload) {
    state[payload.prop] = payload.value
  },
  setSelected(state, list) {
    state.selected = list
  },
  selectBanner(state, list) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      const hashString = md5(item.id + new Date().toString())
      const defaultMatrix3d = [
        '1.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '1.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '1.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '0.000000',
        '1.000000',
      ]
      state.defaultTransform.matrix3d = defaultMatrix3d
      state.defaultTransform.width = 1000
      state.defaultTransform.height = 1000
      state.defaultTransform.idHash = hashString
      const defualtTransformImage = Object.assign(item, state.defaultTransform)
      state.selected.push(defualtTransformImage)
    }
  },
  changeActiveBanner(state, id) {
    state.selected.forEach((element) => {
      if (element.idHash === id) element.active = true
      else element.active = false
    })
  },
  clearSelected(state) {
    state.selected = []
  },
  removeBannerFromSelected(state, id) {
    state.selected.splice(
      state.selected.findIndex((element) => element.idHash === id),
      1
    )
  },
}

const actions = {
  getBanners({ commit, rootState }, params) {
    //  {
    //    surfaces,
    // ratio
    //    color,
    //    banner_type,
    //    hasPartner,
    //    partner,
    //    id,
    //    rootState
    //  } = params

    // TODO возможно тут не нужен Promise
    return new Promise((resolve) => {
      const banners = getFilteredBanners(rootState.dataset, params)

      commit('setStateProps', {
        prop: 'banners',
        value: banners,
      })
      commit('setStateProps', {
        prop: 'status',
        value: 'succeed',
      })
      resolve()
    })
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}

/**
 * @function getFilteredBanners
 * @param {DatasetBanner[]} banners
 * @returns {DatasetBanner[]}
 */
function getFilteredBanners(
  {banners, ratios},
  { id, surface, color, hasPartner, partner, ratio }
) {
  const result = []

  // фильтрация по id
  if (id) {
    const b = banners.find((b) => b.id === +id)
    if (b) {
      result.push(b)
      return result
    }
  }

  // фильтрация по параметрам
  for (const b of banners) {
    if (
      // (!ratio || !b.ratio) ||
      // (!compareRatio(ratio, b.ratio, ratios)) || // соотношение сторон
      (ratio && ratio !== b.ratio) || // соотношение сторон
      (color && color !== b.color) || // цвета
      (hasPartner && partner !== b.partner) || // кобренды / партнеры
      (surface && surface !== b.surface) // поверхности
    ) {
      continue
    }

    result.push(b)
  }

  return result
}

/**
 * @param {{w: number, h: number}} inputRatio
 * @param {number} datasetRatioId
 * @param {DatasetRatio[]} ratios
 * @returns {boolean}
 */
const compareRatio = (inputRatio, datasetRatioId, ratios) => {
  /**
   * @type {DatasetRatio}
   */
  const datasetRatio = ratios.find((r) => r.id === datasetRatioId);

  if(inputRatio.w === datasetRatio.width && inputRatio.h === datasetRatio.height) return true
  return false
}
