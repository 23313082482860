<template>
  <div class="success">
    <h2>{{ success.text }}</h2>
    <button @click="hide" type="button" class="btn btn-next btn-wide">
      {{ success.btnText }}
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const success = computed(() => store.state.success)
const hide = () => {
  if (success.value?.onClick) success.value.onClick()
  store.dispatch('hideSuccess')
}
</script>

<style lang="scss" scoped>
.success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  h2 {
    max-width: 260px;
    margin-bottom: 27px;
    text-align: center;
  }
  button {
    max-width: 208px;
    font-size: 14px;
    padding: 11.5px 17.5px;
  }
}
</style>
