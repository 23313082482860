<template>
  <div class="show-error">
    <h2 class="text">Ошибка:</h2>
    <p class="text">{{ error?.response?.data?.code ?? error.code }}</p>
    <p class="text">{{ error?.response?.data?.message ?? error.message }}</p>
    <button @click="hide" class="btn btn-wide btn-next">ok</button>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const error = computed(() => store.state.error)
const hide = () => {
  store.dispatch('hideError')
}
</script>

<style lang="scss">
.show-error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
  justify-content: center;
  background: white;

  .text {
    max-width: 500px;
    text-align: center;
  }
  .btn {
    max-width: 200px;
  }
}
</style>
