import { getRequestsSchemas } from '@/api/requests'
/**
 * Схема для подписи заявок (approve)
 * @typedef {{
* new: UserRole[],
* prepare: UserRole[],
* negotiate: UserRole[],
* review: UserRole[],
* work: UserRole[],
* report: UserRole[],
* check: UserRole[],
* }} RolesSchema
*/

/**
 * @returns {{
 * approveRolesScheme: RolesSchema | null
 * revokeRolesScheme: RolesSchema | null
 * gotoPrepareStateScheme: RolesSchema | null
 * nextStateSchema: RolesSchema | null
 * }}
 */
const state = () => ({
  approveRolesScheme: null,
  revokeRolesScheme: null,
  gotoPrepareStateScheme: {
    negotiate: ['trademacro', 'merchmacro', 'tradecentral'],
    review: ['tradecentral']
  },
  nextStateSchema: {
    prepare: ['traderegion'],
    // negotiate: [],
    // review: [],
    work: ['traderegion', 'sales'],
    report: ['sales'],
    check: ['traderegion'],
  }
})

const mutations = {
  setStateProps(state, payload) {
    state[payload.prop] = payload.value
  },
}


const actions = {
  async getSchemas({ commit }) {
    try {
      commit('setStateProps', { prop: 'status', value: 'loading' })
      const response = await getRequestsSchemas()
      const schemas = response.states.reduce((acc, next) => {
        acc[next.state] = next.roles
        return acc;
      }, {})
      commit('setStateProps', { prop: 'approveRolesScheme', value: schemas })
      commit('setStateProps', { prop: 'revokeRolesScheme', value: schemas })
      commit('setStateProps', { prop: 'status', value: 'succeed' })
    } catch (error) {
      commit('setStateProps', { prop: 'status', value: 'error' })
      throw error
    }
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
