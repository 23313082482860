import { getToken } from '@/utils/cookie'
import request from '@/utils/request'
import '@/utils/types.js'

/**
 * @function getDataset
 * @returns {Dataset}
 */
export function getDataset() {
  return request({
    url: `/api/dataset/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * @function getStores
 * @param {{city?: string, region: {id?: number, name?: string}, limit: 100, offset: 0}} params
 * @returns {{stores: StoreShort[]}}
 */
export function getStores(params) {
  return request({
    url: `/api/stores/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params,
  })
}
