import { getRequests, setRequestState } from '@/api/requests'
import '@/utils/types.js'

/**
 * @returns {
 *  requests: RequestShort[] | null
 * }
 */
const state = () => ({
  requests: null,
})

const mutations = {
  setStateProps(state, payload) {
    state[payload.prop] = payload.value
  },
  patchRequestState(state, payload) {
    state.requests[payload.id].state = payload.state
  },
}

const actions = {
  /**
   * @param {import('vuex').ActionContext} context
   * @param {{limit?: number, offset?: number}} params
   */
  async fetchRequests({ commit, rootState }, params) {
    try {
      const response = await getRequests(params)
      const filteredRequests = response.requests?.filter((r) => rootState.auth?.me?.regions?.includes(r.store.region.id))
      commit('setStateProps', { prop: 'requests', value: filteredRequests })
    } catch (error) {
      throw new Error(error)
    }
  },
  /**
   * @param {import('vuex').ActionContext} context
   * @param {{requestId: number, method: RequestStateMethods}} params
   * @param {{comment?: string}} [data]
   */
  async changeRequestState(
    { state, dispatch, commit },
    params,
    data = { comment: 'Изменение статуса заявки' }
  ) {
    try {
      const updatedRequest = await setRequestState(params, data)
      const updatedRequestIdx = state.requests.findIndex(
        (r) => r.id === updatedRequest.id
      )
      if (~updatedRequestIdx) {
        commit('patchRequestState', {
          id: updatedRequestIdx,
          state: updatedRequest.state,
        })
      }
    } catch (error) {
      dispatch('showError', error, { root: true })
    }
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
