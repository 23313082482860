/* eslint-disable no-console */

import Cookies from 'js-cookie'
import { register } from 'register-service-worker'

const production = process.env.VUE_APP_NODE_ENV || 'production'

const notifyAboutUpdate = (worker) => {
  alert('Новые изменения! Обновите кеш.')
  Cookies.set('dataset', true)
  worker.postMessage({ action: 'skipWaiting' })
}

if (production === 'production') {
  register(`${process.env.VUE_APP_BASE_URL || '/'}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      notifyAboutUpdate(registration.waiting)
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
