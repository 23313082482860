import { getDataset } from '@/api/dataset'
import { deleteDatasetStore, getDatasetStore, getDB, setDatasetStore } from '@/utils/indexedDB'
import '@/utils/types.js'
import Cookies from 'js-cookie'

// TODO cache or db save

/**
 * @returns {Dataset | null}
 */
const state = () => null

const mutations = {
  setDataset(state, data) {
    for (const key in data) {
      state[key] = data[key]
    }
  },
}

const getters = {
  getDataset(state) {
    return state
  }
}

const actions = {
  async fetchDataset({ commit }) {
    try {
      await getDB()
      // получаем сет с бд если он есть
      const store = await getDatasetStore()
      const dataset = store.result[0];
      // наличие куки указывает на необходимость обновлния
      const datasetNeedUpdate = Cookies.get('dataset')

      if (dataset && !datasetNeedUpdate && dataset.ttl > Date.now()) {
        commit('setDataset', dataset)
        return;
      }
      const data = await getDataset()
      // id нужен для DB
      data.id = 'dataset'
      // время жизни 1 день
      data.ttl = Date.now() + 3600 * 1000 * 24
      // сохраняем в бд
      await deleteDatasetStore()
      await setDatasetStore(data)
      // Удаляем куки если они были
      if(datasetNeedUpdate) Cookies.remove('dataset')
      // сохраняем в стор
      // TODO можно ли читать сразу из бд и не хранить в сторе?
      // Вроде как эти данные не изменяются во время работы приложения
      commit('setDataset', data)
    } catch (error) {
      throw new Error(error)
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
